import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Rhino VR Plugin | Prospect by IrisVR'
const description = `Bring Rhino files into VR with Prospect by IrisVR for immersive design review. Supports Named Views, Materials, Layers, Sun Studies with a one click native plugin.`
const url = `${getWindowLocationOrigin()}/rhino`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
